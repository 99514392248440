<template>
  <div v-if="isModalOpen" class="modal-overlay">
    <div class="modal-content">
      <button @click="closeModal" class="close-button">✖</button>
      <h3>Your Collections</h3>
      <div
        v-if="userstore.user == undefined || userstore.user.email == undefined"
        class="text-center mt-5"
      >
        <p>Please log into create collections</p>
        <button
          class="btn btn-dark w-100"
          style="border-radius: 25px"
          @click="goToLogin"
          v-ripple
        >
          Login
        </button>
      </div>
      <div v-else class="collection-grid">
        <CollectionCard
          v-for="(collection, index) in collections
            .filter((x) => {
              return x.id != 0;
            })
            .sort((a, b) => a.id - b.id)"
          :key="index"
          :collection="collection"
          @click="addtocollection(collection)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import CollectionCard from "@/components/collection/CollectionCard.vue";
import { URLService } from "@/services/_url";
import { UserService } from "@/services/userservice";
import { useUserStore } from "@/stores/userStore";
import { onMounted, ref, defineProps, watch } from "vue";
import { useRouter } from "vue-router";
const userstore = useUserStore();
const router = useRouter();
const collections = ref([]);

onMounted(async () => {
  if (userstore.user && userstore.user.email != undefined) {
    var localCollection = localStorage.getItem("userCollection");
    if (localCollection && localCollection != "") {
      collections.value = JSON.parse(localCollection);
    }
  }
});


const addtocollection = async (collection) => {
  var data = collection.data;
  var localdata = [];
  if (data == null || data == undefined || data == "" || data == "{}")
    localdata = [];
  else
    try {
      localdata = JSON.parse(data);
    } catch {
      localdata = [];
    }
  if (
    localdata.filter((x) => {
      return x.id == props.product.id;
    }).length == 0
  ) {
    localdata.push({
      id: props.product.id,
      name: props.product.name,
      image: props.product.generalurl,
      vendor_name: props.product.vendorname,
    });
    collection.data = JSON.stringify(localdata);

    let data = {
      name: collection.name,
      image: collection.image,
      m_user_id: collection.m_user_id,
      usercode: collection.usercode,
      data: collection.data,
    };
    var url = URLService.updatecollection.replace(
      "{userid}",
      userstore.user?.usercode
    );
    url = url.replace("{colid}", collection.id);
    try {
      if (collection.id == -1) {
        var url2 = URLService.addcollection.replace(
          "{userid}",
          userstore.user?.usercode
        );
        var response = await UserService.createcollection(url2, data);
        if (response.data) {
          var localCollection = localStorage.getItem("userCollection");
          if (localCollection && localCollection != "") {
            let data = JSON.parse(localCollection);
            data.push(response.data.data);
            localStorage.setItem("userCollection", JSON.stringify(data));
          }
        }
      } else {
        var response2 = await UserService.addedtocollection(url, data);
        if (response2.data) {
          var localCollection2 = localStorage.getItem("userCollection");
          if (localCollection2 && localCollection2 != "") {
            let data = JSON.parse(localCollection2);
            data.forEach((element) => {
              if (element.name == response2.data.data.name) {
                element.data = response2.data.data.data;
              }
            });
            localStorage.setItem("userCollection", JSON.stringify(data));
          }
        }
      }
      let a = {
        id: 0,
        name: "All Saved",
        image: "",
        usercode: userstore.user?.usercode,
        m_user_id: userstore.user?.id,
        data: [],
      };

      var localcol = localStorage.getItem("userCollection");
      let _data = [];
      if (localcol && localcol != "") {
        _data = JSON.parse(localcol);
      }
      _data.forEach((element) => {
        let _a = JSON.parse(element.data);
        if (_a) a.data = a.data.concat(_a);
      });
      a.data = JSON.stringify(a.data);
      localStorage.setItem("allcollection", JSON.stringify(a));
    } catch (error) {
      console.log(error);
    }

    userstore.loadproductbookmarksforuserval();
    closeModal();
  } else { 
    closeModal();
  }
};

const props = defineProps({
  modalshow: {
    Boolean,
  },
  product: { Object },
});

watch(
  () => props.modalshow,
  (newval) => {
    console.log(newval);
    if (newval == true) {
      if (
        collections.value == undefined ||
        collections.value == null ||
        collections.value.length == 0
      )
        if (userstore.user && userstore.user.email != undefined) {
          var localCollection = localStorage.getItem("userCollection");
          if (localCollection && localCollection != "") {
            collections.value = JSON.parse(localCollection);
          }
        }
      openModal();
    }
  }
);

const goToLogin = () => {
  router.push({ name: "Login" });
};

const isModalOpen = ref(false);

const openModal = () => {
  isModalOpen.value = true;
};
const closeModal = () => {
  isModalOpen.value = false;
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure modal is on top */
}

.modal-content {
  background-color: white;
  /* White background for modal */
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  /* Max width for modal */
  position: relative;
  height: 600px;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.brand-section {
  margin-top: 1rem;
}

.brand-label {
  background-color: #f1f1f1;
  /* Light background for brand label */
  padding: 0.5rem;
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
}

.collection-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  overflow-y: scroll;
}
</style>

<template>
  <div class="mx-3 mb-3 mt-2">
    <!-- Return Policy Button -->
    <button @click="openModal" class="policy-button">Return Policy</button>

    <!-- Modal for Return Policy -->
    <div v-if="isModalOpen" class="modal-overlay">
      <div class="modal-content">
        <button @click="closeModal" class="close-button">✖</button>
        <h3 style="text-align: center">Return Policy</h3>
        <br />
        <span style="font-size: .8rem; color: #979797;"
          >Returns are reviewed by each individual brand, and it is at the
          discretion of each brand to accept or deny any returns. Wardrobe does
          not manage returns individually. <br /> <br />
          You can submit a return/missing item request through the ‘Return
          Orders’ tab in ‘My Wardrobe’</span
        >
        <div
          v-for="(brand, index) in localPolicies"
          :key="brand.vendor_id"
          class="brand-section"
        >
          <div @click="toggleBrand(index)" class="brand-label">
            {{ brand.vendorname }}
            <span style="float: right">{{ brand.isExpanded ? "▼" : "▲" }}</span>
          </div>
          <div v-if="brand.isExpanded" class="policy-details">
            <!-- <h4>Shipping Policy:</h4>
            <p>{{ brand.shippingPolicy }}</p> -->
            <h4>Return Policy:</h4>
            <div v-html="brand.return_policy"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted } from "vue";

onMounted(() => {
  localPolicies.value = props.policies;
});

const props = defineProps({
  policies: {
    Array,
  },
});

const isModalOpen = ref(false);
const localPolicies = ref([]);

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};

const toggleBrand = (index) => {
  localPolicies.value[index].isExpanded =
    !localPolicies.value[index].isExpanded;
};
</script>

<style scoped>
.policy-button {
  width: 100%;
  padding: 1rem;
  background-color: lightgrey;
  /* Light grey background */
  color: black;
  border: none;
  border-radius: 50px;
  /* Rounded corners */
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.policy-button:hover {
  background-color: #dcdcdc;
  /* Darker grey on hover */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure modal is on top */
}

.modal-content {
  background-color: white;
  /* White background for modal */
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  /* Max width for modal */
  position: relative;
  height: 600px;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.brand-section {
  margin-top: 1rem;
  overflow-y: scroll;
}

.brand-label {
  background-color: #f1f1f1;
  /* Light background for brand label */
  padding: 0.5rem;
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
}

.policy-details {
  margin-top: 0.5rem;
  padding-left: 1rem;
  /* Indent for policy details */
}
</style>

<template>
  <div class="mx-3 mb-3 mt-2">
    <!-- Modal for Return Policy -->
    <div v-if="isModalOpen" class="modal-overlay">
      <div class="modal-content wrapper">
        <div>
          <h3>{{ data.caption ?? "Wardrobe Styles" }}</h3>

          <span
            class="close-button"
            @click="closeModal"
            style="
              position: absolute;
              cursor: pointer;
              padding: 0.3rem;
              right: 2rem;
              top: 1.1rem;
              font-size: x-large;
            "
            >&times;</span
          >
        </div>
        <div v-if="prodata.length == 0">
          <p style="color: grey">No Products Tagged</p>
        </div>
        <div
          v-else
          class="d-flex align-items-center w-full mb-2"
          style="overflow-y: scroll"
          v-for="image in prodata"
          v-bind:key="image.id"
          @click="gotopage(image)"
        >
          <div style="width: 30%">
            <img
              v-if="image.generalurl"
              :src="image.generalurl"
              :alt="image.name"
              style="width: 60px; height: 80px"
            /><img
              v-if="!image.generalurl"
              src="@/assets/300x400.svg"
              :alt="image.name"
              style="width: 60px; height: 80px"
            />
          </div>
          <div style="width: 70%">{{ image.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, watch, defineEmits } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
onMounted(() => {
  localPolicies.value = props.policies;
});

const prodata = ref([]);
const props = defineProps({
  data: {
    Object,
  },
  showmodal: {
    Boolean,
  },
});

const emit = defineEmits(["update:showmodal"]);

watch(
  () => props.showmodal,
  () => {
    prodata.value = JSON.parse(props.data.data);
    openModal();
  }
);

onMounted(() => {
  updateAppHeight(); // Set initial height
  window.addEventListener("resize", updateAppHeight); // Update on resize
});

const gotopage = (image) => {
  router.push({ name: "ProductPage", params: { id: image.id } });
};

const isModalOpen = ref(false);
const localPolicies = ref([]);

const openModal = () => {
  if (props.showmodal == true) isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
  emit("update:showmodal", false);
};

function updateAppHeight() {
  const adjustedHeight = window.innerHeight - window.innerHeight * 0.1;
  document.documentElement.style.setProperty(
    "--app-height",
    `${adjustedHeight}px`
  );
}
</script>

<style scoped>
.policy-button {
  width: 100%;
  padding: 1rem;
  background-color: lightgrey;
  /* Light grey background */
  color: black;
  border: none;
  border-radius: 50px;
  /* Rounded corners */
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.policy-button:hover {
  background-color: #dcdcdc;
  /* Darker grey on hover */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure modal is on top */
}

.modal-content {
  background-color: white;
  /* White background for modal */
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  height: 550px;
  /* Max width for modal */
  position: relative;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  margin-top: 20px;
}

.brand-section {
  margin-top: 1rem;
}

.brand-label {
  background-color: #f1f1f1;
  /* Light background for brand label */
  padding: 0.5rem;
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
}

.policy-details {
  margin-top: 0.5rem;
  padding-left: 1rem;
  /* Indent for policy details */
}

.wrapper {
  /* height: var(--app-height); */
}

.wrapper::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}
</style>

// src/stores/userStore.js
import { encrypteData, decrypteData } from '@/services/crypt/cryptservice';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useUserStore = defineStore('user', () => {
  const user = ref(null);
  const loadproductbookmarksforuser = ref(true)

  const loadproductbookmarksforuserval = () => {
    loadproductbookmarksforuser.value = false;
    setTimeout(() => {
      loadproductbookmarksforuser.value = true;
    }, 1000);
  }

  const loadUserFromStorage = () => {
    const savedCart = decrypteData(localStorage.getItem('user'));
    if (savedCart && savedCart != {} && savedCart != "") {
      user.value = JSON.parse(savedCart);
    }
  }


  const logout = () => {
    user.value = null;
    localStorage.removeItem('user');
  };

  const isLoggedIn = () => {
    return (user.value == undefined || user.value?.email == "" || user.value.email == undefined || user.value.email == null) ? false : true;
  };

  const setLogin = (data) => {
    var str = encrypteData(data);
    localStorage.setItem('user', str);
    user.value = data;
  };

  return { user, logout, isLoggedIn, setLogin, loadUserFromStorage, loadproductbookmarksforuser, loadproductbookmarksforuserval };
});

<!-- <template>
    <div class="modal-overlay" v-if="isVisible">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Select Delivery Location</h3>
          <button class="close-btn" @click="closeGeoLocationPicker">×</button>
        </div>
        <div ref="mapContainer" id="map" class="map-container"></div>
        <div class="modal-footer">
          <button class="confirm-btn" @click="confirmLocation">Select</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
import { loadGoogleMaps } from '@/services/loadGoogleMaps';

  
  export default {
    name: "GeoPickerModal",
    props: {
      shopLocation: {
        type: Object,
        required: true, // Example: { lat: ..., lng: ... }
      },
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        map: null,
        marker: null,
        selectedLocation: null,
        apiKey: "AIzaSyBG1HicY2KuoLcuYNJvF0wDsGLR_LgXfPo", // Replace with your Google Maps API key
      };
    },
    methods: {
      async initializeMap() {
        try {
          // Ensure the map container exists before initializing
          await this.$nextTick();
  
          const mapElement = this.$refs.mapContainer;
  
          if (!mapElement) {
            console.error("Map container is not available");
            return;
          }
  
          // Load Google Maps API dynamically
          await loadGoogleMaps(this.apiKey);
  
          // Initialize the map centered on the shop location
          
        /* global google */
          this.map = new google.maps.Map(mapElement, {
            center: this.shopLocation,
            zoom: 14,
          });
  
          // Add a draggable marker to the map
          this.marker = new google.maps.Marker({
            position: this.shopLocation,
            map: this.map,
            draggable: true,
          });
  
          // Set the initial selected location to the marker's position
          this.selectedLocation = { ...this.shopLocation };
  
          // Update selectedLocation when the marker is dragged
          this.marker.addListener("dragend", () => {
            const position = this.marker.getPosition();
            this.selectedLocation = {
              lat: position.lat(),
              lng: position.lng(),
            };
          });
  
          // Add a click listener to the map to reposition the marker
          this.map.addListener("click", (event) => {
            const position = {
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
            };
  
            // Update marker position
            this.marker.setPosition(position);
  
            // Update selected location
            this.selectedLocation = position;
          });
        } catch (error) {
          console.error("Failed to load Google Maps API:", error);
        }
      },
      async confirmLocation() {
        // Check the driving distance to the shop location
        if (!this.selectedLocation) return;
  
        const distance = await this.calculateDistance(
          this.shopLocation,
          this.selectedLocation
        );
  
        if (distance > 25) {
          alert(
            "The selected location is more than 25 km away from the shop. Delivery is not available."
          );
          return;
        }
  
        // Emit the selected location to the parent component
        this.$emit("locationSelected", this.selectedLocation);
  
        // Close the modal
        this.closeGeoLocationPicker();
      },
      calculateDistance(origin, destination) {
        return new Promise((resolve, reject) => {
          const service = new google.maps.DistanceMatrixService();
          service.getDistanceMatrix(
            {
              origins: [origin],
              destinations: [destination],
              travelMode: "DRIVING",
            },
            (response, status) => {
              if (status === "OK") {
                const distanceInMeters =
                  response.rows[0].elements[0].distance.value;
                const distanceInKm = distanceInMeters / 1000;
                resolve(distanceInKm);
              } else {
                reject("Failed to calculate distance: " + status);
              }
            }
          );
        });
      },
      closeGeoLocationPicker() {
        this.$emit("closeModal");
      },
    },
    watch: {
      isVisible(newValue) {
        if (newValue) {
          this.initializeMap();
        }
      },
    },
  };
  </script> -->

<!-- 
<template>
  <div class="modal-overlay" v-if="isVisible">
    <div class="modal-container">
      <div class="modal-header">
        <h3>Select Delivery Location</h3>
        <button class="close-btn" @click="closeModal">×</button>
      </div>
      <div id="map" class="map-container"></div>
      <div class="modal-footer">
        <button class="confirm-btn" @click="confirmLocation">Select</button>
      </div>
    </div>
  </div>
</template>

<script>
import { loadGoogleMaps } from "@/services/loadGoogleMaps";

export default {
  name: "GeoPickerModal",
  props: {
    shopLocation: {
      type: Object,
      required: true, // e.g., { lat: ..., lng: ... }
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      map: null,
      marker: null,
      selectedLocation: null,
      apiKey: "AIzaSyBG1HicY2KuoLcuYNJvF0wDsGLR_LgXfPo", // Replace with your Google Maps API key
    };
  },
  methods: {
    async initializeMap() {
      try {
        // Load Google Maps API dynamically
        await loadGoogleMaps(this.apiKey);

        // Initialize the map
        /* global google */
        this.map = new google.maps.Map(document.getElementById("map"), {
          center: this.shopLocation,
          zoom: 14,
        });

        // Add a draggable marker at the shop's location
        this.marker = new google.maps.Marker({
          position: this.shopLocation,
          map: this.map,
          draggable: true,
        });

        // Update selectedLocation when the marker is moved
        this.marker.addListener("dragend", () => {
          const position = this.marker.getPosition();
          this.selectedLocation = {
            lat: position.lat(),
            lng: position.lng(),
          };
        });

        // Set initial location as the shop's location
        this.selectedLocation = this.shopLocation;
      } catch (error) {
        console.error("Failed to load Google Maps API:", error);
      }
    },
    async confirmLocation() {
      // Check driving distance to shop location
      if (!this.selectedLocation) return;

      const distance = await this.calculateDistance(
        this.shopLocation,
        this.selectedLocation
      );

      if (distance > 25) {
        alert(
          "The selected location is more than 25 km away from the shop. Delivery is not available."
        );
        return;
      }

      // Emit the selected location to the parent component
      this.$emit("locationSelected", this.selectedLocation);
      this.closeModal();
    },
    calculateDistance(origin, destination) {
      return new Promise((resolve, reject) => {
        const service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: "DRIVING",
          },
          (response, status) => {
            if (status === "OK") {
              const distanceInMeters =
                response.rows[0].elements[0].distance.value;
              const distanceInKm = distanceInMeters / 1000;
              resolve(distanceInKm);
            } else {
              reject("Failed to calculate distance: " + status);
            }
          }
        );
      });
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
  watch: {
    isVisible(newValue) {
      if (newValue) {
        this.initializeMap();
      }
    },
  },
};
</script> 

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background: #fff;
  width: 90%;
  max-width: 600px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modal-header {
  padding: 1rem;
  background: #007bff;
  color: white;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.map-container {
  width: 100%;
  height: 400px;
}

.modal-footer {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.confirm-btn {
  background: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-btn:hover {
  background: #0056b3;
}

.close-btn {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}

.close-btn:hover {
  color: #ccc;
}
</style> -->

<template>
  <div class="modal-overlay" v-if="isVisible">
    <div class="modal-container">
      <div class="modal-header">
        <h3>Select Delivery Location</h3>
        <button class="close-btn" @click="closeGeoLocationPicker">×</button>
      </div>

      <div class="search-container">
        <input
          ref="locationInput"
          type="text"
          placeholder="Search for a location"
          class="search-input"
          @input="debounceSearchLocation"
        />
        <ul v-if="searchResults.length" class="dropdown">
          <li
            v-for="(result, index) in searchResults"
            :key="index"
            @click="selectSearchResult(result)"
          >
            {{ result.description }}
          </li>
        </ul>
      </div>
      <div class="map-wrapper">
        <div ref="mapContainer" id="map" class="map-container"></div>
        <div class="marker-center">
          <img src="@/assets/marker-icon.svg" alt="Marker" />
        </div>
      </div>
      <div class="modal-footer">
        <button class="confirm-btn" @click="confirmLocation">
          Set Delivery Location
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { loadGoogleMaps } from "@/services/loadGoogleMaps";
import { Geolocation } from "@capacitor/geolocation";
import { debounce } from "lodash";

export default {
  name: "GeoPickerModal",
  props: {
    shopLocation: {
      type: Object,
      required: true, // Example: { lat: ..., lng: ... }
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      map: null,
      marker: null,
      selectedLocation: null,
      apiKey: "AIzaSyBG1HicY2KuoLcuYNJvF0wDsGLR_LgXfPo", // Replace with your Google Maps API key
      shoploc: null,
      autocompleteService: null,
      placesService: null,
      searchResults: [],
      debounceSearchLocation: null,
    };
  },
  created() {
    // Initialize the debounced method
    this.debounceSearchLocation = debounce(this.searchLocation, 300);
  },
  methods: {
    async initializeMap() {
      try {
        await this.$nextTick();

        const mapElement = this.$refs.mapContainer;

        if (!mapElement) {
          console.error("Map container is not available");
          return;
        }

        // Load Google Maps API dynamically
        await loadGoogleMaps(this.apiKey);

        // Get current location using Capacitor Geolocation
        const position = await Geolocation.getCurrentPosition();
        const currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        // Initialize the map centered on the shop location

        /* global google */
        this.map = new google.maps.Map(mapElement, {
          center: currentLocation,
          zoom: 15,
          mapId: "f8b41595cb2b364f",
        });
        if (this.shopLocation != null) {
          let lat = this.shopLocation.lat.trimStart().trimEnd();
          let lng = this.shopLocation.lng.trimStart().trimEnd();
          this.shoploc = { lat: parseFloat(lat), lng: parseFloat(lng) };
          this.marker = new google.maps.Marker({
            position: { lat: parseFloat(lat), lng: parseFloat(lng) },
            map: this.map,
            draggable: false,
          });
        }

        this.placesService = new google.maps.places.PlacesService(this.map);
        this.autocompleteService = new google.maps.places.AutocompleteService();

        // Update the selected location as the map center when moved
        this.map.addListener("center_changed", () => {
          const center = this.map.getCenter();
          this.selectedLocation = {
            lat: center.lat(),
            lng: center.lng(),
          };
        });

        // Set the initial selected location
        this.selectedLocation = {
          lat: currentLocation.lat,
          lng: currentLocation.lng,
        };
      } catch (error) {
        console.error("Failed to load Google Maps API:", error);
      }
    },
    async searchLocation() {
      const query = this.$refs.locationInput.value;
      if (!query) {
        this.searchResults = [];
        return;
      }

      // Use AutocompleteService to fetch suggestions
      this.autocompleteService.getPlacePredictions(
        { input: query },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            this.searchResults = predictions || [];
          } else {
            this.searchResults = [];
          }
        }
      );
    },
    selectSearchResult(result) {
      // Use PlacesService to get details about the selected place
      this.placesService.getDetails(
        { placeId: result.place_id },
        (place, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            place.geometry
          ) {
            const location = place.geometry.location;
            this.map.setCenter(location);

            // Update the selected location
            this.selectedLocation = {
              lat: location.lat(),
              lng: location.lng(),
            };
            console.log(this.selectedLocation);

            // Clear the search results and input field
            this.searchResults = [];
            this.$refs.locationInput.value = "";
          } else {
            console.error("Failed to retrieve place details:", status);
          }
        }
      );
    },
    async confirmLocation() {
      if (!this.selectedLocation) return;
      const distance = await this.calculateDistance(
        this.shoploc,
        this.selectedLocation
      );

      //   if (distance > 25) {
      //     alert(
      //       "The selected location is more than 25 km away from the shop. Delivery is not available."
      //     );
      //     return;
      //   } else {
      //     alert(
      //       "The selected location is less than 25 km away from the shop. Delivery is available."
      //     );
      //   }

      // Emit the selected location to the parent component
      this.$emit("locationSelected", {
        loc: this.selectedLocation,
        distance: distance,
      });

      // Close the modal
      this.closeGeoLocationPicker();
    },
    calculateDistance(origin, destination) {
      return new Promise((resolve, reject) => {
        const service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: "DRIVING",
          },
          (response, status) => {
            if (status === "OK") {
              const distanceInMeters =
                response.rows[0].elements[0].distance.value;
              const distanceInKm = distanceInMeters / 1000;
              resolve(distanceInKm);
            } else {
              reject("Failed to calculate distance: " + status);
            }
          }
        );
      });
    },
    async closeGeoLocationPicker() {
      this.$emit("closeModal");
    },
  },
  watch: {
    isVisible(newValue) {
      if (newValue) {
        this.initializeMap();
      }
    },
    shopLocation(newValue) {
      if (newValue) {
        this.initializeMap();
      }
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background: #fff;
  width: 90%;
  max-width: 600px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modal-header {
  padding: 1rem;
  background: #000;
  color: white;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.map-container {
  width: 100%;
  height: 400px;
  position: relative;
}

.modal-footer {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.close-btn {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #fff;
}
.map-wrapper {
  position: relative; /* Make the wrapper relative to position child elements */
  width: 100%; /* Ensure the wrapper takes full width */
  height: 350px; /* Ensure the wrapper takes full height, or set a fixed height if necessary */
}

.map-container {
  width: 100%;
  height: 100%;
  position: relative; /* Make the map container relative for child elements like the marker */
}

.marker-center {
  position: absolute; /* Position the marker relative to the map-container */
  top: 155px;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the marker */
  pointer-events: none; /* Avoid the marker interfering with map interactions */
  z-index: 10; /* Ensure the marker is above the map */
}

.marker-center img {
  width: 40px; /* Marker icon size */
  height: 40px; /* Marker icon size */
}
.modal-footer {
  padding: 10px;
  text-align: right;
}

.confirm-btn {
  background-color: #000;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.search-container {
  margin: 1rem;
  position: relative;
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1001;
}

.dropdown li {
  padding: 0.5rem;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #f0f0f0;
}
</style>

<template>
  <div class="runaway-page">
    <TopButtons
      :buttons="['backbtn', 'title', 'edit']"
      :options="topvarOptions"
      :isedit="isedit"
      @clickedit="clickedit"
      v-if="loaded == true"
    />
    <div v-if="images.length == 0" class="text-center mt-5" style="color: gray">
      No items added yet
    </div>
    <div
      class="grid-container mt-2"
      style="max-height: 85vh; overflow-y: auto; overflow-x: hidden"
    >
      <div
        v-for="(image, index) in images"
        :key="index"
        :class="index % 6 == 1 || index % 6 == 3 ? 'grid-item' : 'grid-item'"
        @click="gotoproduct(image)"
      >
        <div class="collection-edit" v-if="isedit == true">
          <button
            class="delete"
            style="
              width: 25px !important;
              height: 25px !important;
              font-size: 0.7rem !important;
              margin-top: 8px;
            "
            @click.stop="updatecollection(image)"
          >
            <i class="fa-solid fa-trash-can"></i>
          </button>
        </div>

        <img :src="image.image" :alt="image.vendor_name" class="image" />
        <div class="image-info">
          <small>From {{ image.vendor_name }}</small>
        </div>
      </div>
    </div>
  </div>
  <RunAway
    :data="selectedrunaway"
    :showmodal="showmodal"
    @update:showmodal="showmodal = !showmodal"
  />

  <ion-toast
    id="toast"
    trigger="open-toast"
    :is-open="isOpen"
    message="You don't have access to see the collection"
    :duration="3000"
  ></ion-toast>

  <div
    v-if="showModal == true"
    class="custom-modal-overlay"
    @click.self="closeModal"
  >
    <div class="custom-modal">
      <div class="modal-header">
        <h5>Confirm Action</h5>

        <span
          class="close-button"
          @click="closeModal"
          style="
            cursor: pointer;
            padding: 0.3rem;
            right: 2rem;
            top: 1.1rem;
            font-size: x-large;
          "
          >&times;</span
        >
      </div>
      <div class="modal-body">
        <p>Delete Bookmark?</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary border-round" @click="closeModal">
          No
        </button>
        <button class="btn btn-danger border-round" @click="confirmation">
          Yes
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import RunAway from "@/components/RunAway.vue";
import { IonToast } from "@ionic/vue";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import TopButtons from "./common/TopButtons.vue";
import { useUserStore } from "@/stores/userStore";
import { URLService } from "@/services/_url";
import { UserService } from "@/services/userservice";

const route = useRoute();
const router = useRouter();
const images = ref([]);

const selectedrunaway = ref(null);
const showmodal = ref(false);
const isOpen = ref(false);
const name = ref("");
const topvarOptions = ref();
const loaded = ref(false);

const selcollection = ref(null);

const showModal = ref(false);
const updatecollection = (image) => {
  selcol.value = image;
  showModal.value = true;
};
const closeModal = () => {
  showModal.value = false;
};
const confirmation = () => {
  showModal.value = false;
  updatingcollection();
};

const gotoproduct = (image) => {
  router.push({ name: "ProductPage", params: { id: image.id } });
};

const selcol = ref({});
const userstore = useUserStore();
const updatingcollection = async () => {
  images.value.splice(images.value.indexOf(selcol.value), 1);
  var a = selcollection.value;
  let data = {
    name: a.name,
    image: a.image,
    data: JSON.stringify(images.value),
    m_user_id: a.m_user_id,
    usercode: userstore.user?.usercode,
  };
  let url = URLService.updatecollection.replace(
    "{userid}",
    userstore.user?.usercode
  );
  url = url.replace("{colid}", a.id);
  let response = await UserService.addedtocollection(url, data);
  if (response.data) {
    var localCollection = localStorage.getItem("userCollection");
    if (localCollection && localCollection != "") {
      let data = JSON.parse(localCollection);

      let a = data.filter((x) => {
        return x.id == selcol.value.id;
      });
      if (a.length > 0) {
        let ind = data.indexOf(a);
        data[ind] = response.data;
      }
      localStorage.setItem("userCollection", JSON.stringify(data));
    }
  }
};

onMounted(async () => {
  let id = route.params.id;

  var localCollection = localStorage.getItem("userCollection");
  if (localCollection && localCollection != "") {
    let collections = JSON.parse(localCollection);
    let selCollection = collections.filter((x) => {
      return x.id == id;
    });
    if (selCollection.length > 0) {
      try {
        if (selCollection[0].data == "" || selCollection[0].data == null) {
          images.value = [];
          name.value = selCollection[0].name;
          topvarOptions.value = { title: name.value };
          loaded.value = true;
          selcollection.value = selCollection[0];
          return;
        }
        let data = JSON.parse(selCollection[0].data);
        name.value = selCollection[0].name;
        topvarOptions.value = { title: name.value };
        selcollection.value = selCollection[0];
        images.value = data;
      } catch (error) {
        isOpen.value = true;
        setTimeout(() => {
          router.go(-1);
        }, 4000);
      }
      loaded.value = true;
    } else {
      loaded.value = true;
      router.go(-1);
    }
  }
});

const isedit = ref(false);
const clickedit = () => {
  isedit.value = !isedit.value;
};
</script>

<style scoped>
.runaway-page {
  padding: 1rem;
}

.collection-header {
  display: flex;
  justify-content: center;
  margin: 0px 26px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.grid-container::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.grid-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}

.grid-item {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  /* aspect-ratio: 3 / 4;  */
  /* Standard image */
}

.grid-item-large {
  grid-column: span 2;
  grid-row: span 2;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  aspect-ratio: 2 / 3; /* Large image in portrait format */
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.image-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(255, 255, 255, 0) 76%
  );
  color: white;
  text-align: left;
  border-radius: 0 0 15px 15px;
}
.collection-edit {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  color: white;
  padding: 0.5rem;
  border-radius: 0px 0px 10px 10px;
  font-weight: bold;
}
.collection-edit > .delete {
  position: absolute;
  background-color: black;
  color: rgb(254, 1, 1);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 5px;
}
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal container */
.custom-modal {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
}

/* Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Close button */
.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #888;
  cursor: pointer;
}

/* Body and Footer */
.modal-body {
  margin-bottom: 20px;
  text-align: center;
}
.modal-footer {
  display: flex;
  justify-content: space-between;
}
.modal-footer .btn {
  flex: 1;
  margin: 0 5px;
}

/* Animation for modal */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>

<template>
  <div 
    :class="platform == 'ios' ? 'cart-summary-ios': 'cart-summary'" class="">
    <button
      class="btn btn-dark w-100 mt-3 mb-2"
      @click="emit('checkout')"
      style="border-radius: 50px; padding: 1rem;; font-size: 1.2rem" v-ripple
    >
      Checkout
    </button>
  </div>

  <div v-if="showModal" class="custom-modal-overlay" @click.self="closeModal">
    <div class="custom-modal">
      <div class="modal-header">
        <h5>Confirm Action</h5>

        <span
          class="close-button"
          @click="closeModal"
          style="
            cursor: pointer;
            padding: 0.3rem;
            right: 2rem;
            top: 1.1rem;
            font-size: x-large;
          "
          >&times;</span
        >
      </div>
      <div class="modal-body">
        <p>Clear Cart?</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary border-round" @click="closeModal">
          Cancel
        </button>
        <button class="btn btn-danger border-round" @click="confirmClearCart">
          Yes, Clear Cart
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCartStore } from "@/stores/cartStore";
import { defineProps, onMounted, ref, defineEmits } from "vue";
import { Device } from "@capacitor/device";
const cartstore = useCartStore();

defineProps({
  subtotal: String,
  total: String,
  discount: String,
});

const emit = defineEmits(["checkout"]);

// const clearCart = () => {
//   showModal.value = true;
// };
const platform = ref(null);
onMounted(async () => {

  try {
    const info = await Device.getInfo(); // eslint-disable-next-line
    platform.value = info.platform; // e.g., "ios", "android", "web"
  } catch (error) {
    console.error("Error getting device info:", error);
  }
});

const showModal = ref(false);

function closeModal() {
  showModal.value = false;
}

function confirmClearCart() {
  cartstore.clearcart();
  window.location.reload();
}
</script>

<style scoped>
.cart-summary {
  position: sticky;
  background-color: white;
}

.cart-summary-ios {
  position: sticky;
  background-color: white;
  margin-bottom: 7.5vh;
}

.btn-dark {
  background-color: #000;
}

.btn-dark:hover {
  background-color: #333;
}

.price-col {
  text-align: right;
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal container */
.custom-modal {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
}

/* Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Close button */
.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #888;
  cursor: pointer;
}

/* Body and Footer */
.modal-body {
  margin-bottom: 20px;
  text-align: center;
}
.modal-footer {
  display: flex;
  justify-content: space-between;
}
.modal-footer .btn {
  flex: 1;
  margin: 0 5px;
}

/* Animation for modal */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.border-round {
  border-radius: 50px;
}
</style>

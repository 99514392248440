<template>
  <div class="border p-3 rounded">
    <h5>Order Summary</h5>
    <div class="d-flex justify-content-between my-2">
      <span>Subtotal</span>
      <span> {{ subtotal }}</span>
    </div>
    <div
      class="d-flex justify-content-between my-2"
      v-if="order.discount != 0 && order.discount != undefined"
    >
      <span>Discount</span>
      <span>{{ discount }}</span>
    </div>
    <div class="d-flex justify-content-between my-2">
      <span>Delivery Fee</span>
      <div>
        <span v-if="order.freedelivery == false">{{ deliveryFee }}</span>
        <span
          v-if="order.freedelivery == true"
          class="text-danger"
          style="text-decoration: line-through; margin-right: 10px"
          >{{ deliveryFee }}</span
        >
        <span v-if="order.freedelivery == true">LKR 0</span>
      </div>
    </div>
    <div
      class="d-flex justify-content-between my-2"
      v-if="order.promoAmount != 0 && order.promoAmount != undefined"
    >
      <span>Promo Discount</span>
      <span>{{ promototal }}</span>
    </div>
    <div
      class="d-flex justify-content-between my-2"
      v-if="order.taxes > 0 && order.taxes != undefined"
    >
      <span
        >Handling Fee

        <span class="info-icon-container">
          <i class="fa fa-info-circle ml-2" style="cursor: pointer"></i>
          <span class="tooltip-text"
            > The handling fee helps cover the cost of collecting, preparing, and dispatching your order to ensure a seamless delivery experience.
            </span
          >
        </span>
      </span>
      <span>{{ taxes }}</span>
    </div>
    <hr />
    <div class="d-flex justify-content-between fw-bold">
      <span>Total</span>
      <span>{{ total }}</span>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
const priceFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "LKR",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const props = defineProps({
  order: {
    type: Object,
    required: true,
    default: () => ({
      subtotal: 0,
      deliveryFee: 0,
      taxes: 0,
      total: 0,
      discount: 0,
      promoAmount: 0,
      freedelivery: false,
    }),
  },
});

const promototal = computed(() => {
  return priceFormat.format(props.order.promoAmount * -1);
});
const deliveryFee = computed(() => {
  return priceFormat.format(props.order.deliveryFee);
});
const subtotal = computed(() => {
  return priceFormat.format(props.order.subtotal);
});
const discount = computed(() => {
  return priceFormat.format(props.order.discount);
});
const taxes = computed(() => {
  return priceFormat.format(props.order.taxes);
});
const total = computed(() => {
  return priceFormat.format(props.order.total);
});
</script>

<style scoped>
/* Add styling as needed */

.info-icon-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 300px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.info-icon-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
</style>

// src/utils/loadGoogleMaps.js
export function loadGoogleMaps(apiKey) {
    return new Promise((resolve, reject) => {
      if (typeof google !== "undefined") {
        /* global google */
        resolve(google);
        return;
      }
  
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
  
      script.onload = () => resolve(google);
      script.onerror = () => reject(new Error("Failed to load Google Maps API."));
  
      document.head.appendChild(script);
    });
  }
  
<template>
  <div class="d-flex align-items-center " style="width: 100%;">
    <div class="input-group">
      <input 
        v-model="localPhoneNumber"
        placeholder="706554655"
        @input="onInputChange"
        :id="textId" class="form-control" style="width: 100%;"/>
    </div>

  </div>
</template>

<script setup>
import { onMounted, defineProps,defineEmits, computed, ref  } from "vue";
import intlTelInput from "intl-tel-input";

onMounted(() => {
  const input = document.querySelector( props.phoneid == undefined ? '#phone' : "#" + props.phoneid);
  iti.value = intlTelInput(input, {
    customPlaceholder: () => "e.g. 706554655",
    loadUtilsOnInit: () => import("intl-tel-input/utils"),
    initialCountry: 'LK',
    containerClass: 'w-full'
  });
});

const iti = ref({});

const textId = computed(() => {
    let a = props.phoneid == undefined ? 'phone' :  props.phoneid
    return a
}); 

const props = defineProps({
    phoneid: String,
    phonenumber: Number,
})
const emit = defineEmits(["update:phonenumber"]);

const localPhoneNumber = ref(props.phonenumber || "");
const onInputChange = () => {
    
    if(iti.value.isValidNumber() == true){
        let Telnumber = iti.value.getNumber(intlTelInput.utils.numberFormat.E164);
        localPhoneNumber.value = Telnumber
        emit("update:phonenumber", Telnumber);
    }
};

</script>

<style scoped>
.country-flag {
  width: 24px; /* Adjust the flag size as needed */
  height: auto;
  margin-right: 5px; /* Space between flag and country code */
}
.input-group {
  width: 100%; /* Make input group take full width */
}

.w-full{
    width: 100% !important;
}
</style>

<!-- src/components/ProductGrid.vue -->
<template>
  <div class="product-grid mt-2 mb-2" ref="productGrid" @scroll="handleScroll">
    <ImageCarousel v-if="showslider == true" :products="_prods" class="mb-3" />

    <div class="row" v-if="isSmall == true">
      <div
        class="col-6 col-md-3 mb-3"
        v-for="product in products"
        :key="product.id"
      >
        <ProductCardSmall :product="product" :approveval="approveval" />
      </div>
    </div>
    <div
      class="row"
      v-if="isSmall == false || isSmall == undefined || isSmall == null"
    >
      <div
        class="col-6 col-md-3 mb-3"
        v-for="product in products"
        :key="product.id"
      >
        <ProductCard
          :product="product"
          @add-to-collection="onAddToCollection"
        />
      </div>
    </div>
    <div  v-if="loading"
      class="text-center w-100 mt-3"
      style="color: rgb(130 130 130); font-size: 0.7rem"
    >
      <p>Loading Products...</p>
    </div>
    <div
      v-if="platform == 'ios'"
      class="text-center w-100"
      style="height: 12vh"
    ></div>

    <div
      v-if="platform != 'ios'"
      class="text-center w-100"
      style="height: 8vh"
    ></div>
  </div>

  <AddToCollection :modalshow="isshowmodalofcollection" :product="_product" />
</template>

<script setup>
import { defineProps, ref, defineEmits, onMounted, onBeforeUnmount } from "vue";
import ProductCard from "./ProductCard.vue";
import ProductCardSmall from "./ProductCardSmall.vue";
import ImageCarousel from "./home/ImageCarousel.vue";
import { Device } from "@capacitor/device";
import AddToCollection from "./collection/AddToCollection.vue";
import { URLService } from "@/services/_url";
import { UserService } from "@/services/userservice";
import { useUserStore } from "@/stores/userStore";
const _prods = ref([
  { image: "./300_400.png" },
  { image: "./300_400.png" },
  { image: "./300_400.png" },
  { image: "./300_400.png" },
  { image: "./300_400.png" },
]);
const platform = ref(null);

defineProps({
  products: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  isSmall: {
    type: Boolean,
    default: false,
  },
  showslider: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["load-more", "add-to-collection"]);
const previousScrollTop = ref(0); // Store the previous scroll position
const productGrid = ref(null); // Reference to the product grid

const isshowmodalofcollection = ref(false);
const _product = ref(null);
const onAddToCollection = (product) => {
  _product.value = product;
  isshowmodalofcollection.value = false;
  setTimeout(() => {
    isshowmodalofcollection.value = true;
  }, 200);

  console.log("Product added to collection:", product);
};

const handleScroll = () => {
  const grid = productGrid.value;
  const currentScrollTop = grid.scrollTop;

  // Only trigger load-more if user scrolls down
  if (
    currentScrollTop > previousScrollTop.value &&
    grid.scrollHeight - grid.scrollTop <= grid.clientHeight + 50
  ) {
    emit("load-more");
  }

  // Update the previous scroll position
  previousScrollTop.value = currentScrollTop;
};

// Optional: Cleanup listeners if you have mounted listeners
const activeSlide = ref(null);
const userStore = useUserStore();
onMounted(async () => {
  try {
    const info = await Device.getInfo(); // eslint-disable-next-line
    platform.value = info.platform; // e.g., "ios", "android", "web"
  } catch (error) {
    console.error("Error getting device info:", error);
  }

  const carouselElement = document.getElementById("carouselExampleControls");
  // const bsCarousel = new Carousel(carouselElement);
  carouselElement?.addEventListener("slide.bs.carousel", (event) => {
    activeSlide.value = event.to;
  });

  productGrid.value.addEventListener("scroll", handleScroll);
  updateAppHeight(); // Set initial height
  window.addEventListener("resize", updateAppHeight); // Update on resize

  try {
    await userStore.loadUserFromStorage();
    if (userStore.user?.usercode) {
      setcollection();
    }
  } catch (error) {
    console.log(error);
  }
});

const setcollection = async () => {
  try {
    var url = URLService.getcollections.replace(
      "{userid}",
      userStore.user?.usercode
    );
    var response = await UserService.getcollections(url);
    if (response.status == 200) {
      if (response.data) {
        let a = {
          id: 0,
          name: "All Saved",
          image: "",
          usercode: userStore.user?.usercode,
          m_user_id: userStore.user?.id,
          data: [],
        };
        let isdefaultavailable = false;
        response.data.forEach((element) => {
          if (element.name == "Favourites") {
            isdefaultavailable = true;
          }
          let _a = JSON.parse(element.data);
          if (_a) a.data = a.data.concat(_a);
        });

        let finaldata = response.data;
        if (isdefaultavailable == false) {
          let b = {
            id: -1,
            name: "Favourites",
            image:
              "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/25.jpg",
            usercode: userStore.user?.usercode,
            m_user_id: userStore.user?.id,
            data: [],
          };
          b.data = JSON.stringify(b.data);
          finaldata.push(b);
        }

        a.data = JSON.stringify(a.data);
        finaldata.push(a);

        localStorage.setItem("allcollection", JSON.stringify(a));
        localStorage.setItem("userCollection", JSON.stringify(finaldata));
      }
    }
  } catch (error) {
    console.log(error);
  }
};

onBeforeUnmount(() => {
  productGrid.value.removeEventListener("scroll", handleScroll);
});

function updateAppHeight() {
  const adjustedHeight =
    window.innerHeight - window.innerHeight * 0.07 - window.innerHeight * 0.08;
  document.documentElement.style.setProperty(
    "--app-height",
    `${adjustedHeight}px`
  );
}
</script>

<style scoped>
/* .product-grid {
  max-height: 83vh;
  overflow-y: auto;
  overflow-x: hidden;
} */

.product-grid {
  height: var(--app-height);
  overflow-y: auto;
  overflow-x: hidden;
}

.product-grid {
  overflow-y: scroll;
  gap: 1rem;
  /* Space between reviews */
  scrollbar-width: none;
}

.product-grid::-webkit-scrollbar {
  display: none;
  /* Hides scrollbar in Chrome, Safari */
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns for mobile */
  gap: 1rem;
}

.grid-item img {
  width: 100%; /* Ensure images take the full width */
  height: auto;
  border-radius: 5px;
}

/* Media query for desktop */
@media (min-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr); /* 4 columns on desktop */
  }
}
</style>
